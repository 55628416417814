import { ColDef, GetQuickFilterTextParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";

import CheckboxCell from "@components/CatalogItems/Cells/CheckboxCell";
import { ICheckboxCellOwnProps } from "@components/CatalogItems/Cells/CheckboxCell/CheckboxCell";
import CheckboxHeaderCell from "@components/CatalogItems/Cells/CheckboxHeaderCell";
import ChildRefsCell from "@components/CatalogItems/Cells/ChildRefsCell";
import CommentsCell from "@components/CatalogItems/Cells/CommentsCell";
import CostCurrencyCell from "@components/CatalogItems/Cells/CostCurrencyCell";
import LeadTimePeriodCell from "@components/CatalogItems/Cells/LeadTimePeriodCell";
import ParentRefsCell from "@components/CatalogItems/Cells/ParentRefsCell";
import PartNumberCellEditor from "@components/CatalogItems/Cells/PartNumberCellEditor";
import { PartTypeCell } from "@components/CatalogItems/Cells/PartTypeCell";
import { PdmActionsCell } from "@components/CatalogItems/Cells/PdmActionsCell";
import PdmNameCell from "@components/CatalogItems/Cells/PdmNameCell";
import PdmThumbnailCell from "@components/CatalogItems/Cells/PdmThumbnailCell";
import SourceCell from "@components/CatalogItems/Cells/SourceCell";
import StatusCell from "@components/CatalogItems/Cells/StatusCell";
import ValueCell from "@components/CatalogItems/Cells/ValueCell";
import VersionCell from "@components/CatalogItems/Cells/VersionCell";
import WeightUnitCell from "@components/CatalogItems/Cells/WeightUnitCell";
import { CatalogItemNode, CatalogItemTableColumn } from "@components/CatalogItems/Table/types";
import {
  getRowId,
  versionCellLeadTimeValueGetter,
  versionCellLeadTimeValueSetter,
  versionCellValueGetter,
  versionCellValueSetter,
} from "@components/CatalogItems/Table/utils";
import PositiveDecimalCellEditor from "@components/Table/CellEditors/PositiveDecimalCellEditor";
import PositiveIntegerCellEditor from "@components/Table/CellEditors/PositiveIntegerCellEditor";
import TextCellEditor from "@components/Table/CellEditors/TextCellEditor";
import { TextCellRenderer } from "@components/Table/CellRenderers/TextCellRenderer";
import appStore from "@store/AppStore";

import { ICheckboxHeaderCellOwnProps } from "../Cells/CheckboxHeaderCell/CheckboxHeaderCell";

import checkboxHeaderCellStyles from "../Cells/CheckboxHeaderCell/CheckboxHeaderCell.module.scss";
import pdmNameCellStyles from "@components/CatalogItems/Cells/PdmNameCell/PdmNameCell.module.scss";
import pdmThumbnailCellStyles from "@components/CatalogItems/Cells/PdmThumbnailCell/PdmThumbnailCell.module.scss";
import versionCellStyles from "@components/CatalogItems/Cells/VersionCell/VersionCell.module.scss";

export const defaultColDef: ColDef = {
  editable: false,
  autoHeight: false,
  singleClickEdit: true,
  resizable: true,
  suppressMovable: true,
};

export const checkboxColumnDef: ColDef<CatalogItemNode> = {
  colId: CatalogItemTableColumn.Checkbox,
  resizable: false,
  headerName: "",
  checkboxSelection: true,
  lockPosition: "left" as const,
  pinned: "left" as const,
  width: 25,
  cellRenderer: CheckboxCell,
  cellRendererParams: {
    onToggleSelection: catalogItemNode => {
      const rowId = getRowId(catalogItemNode);
      appStore.ui.toggleSelectedCatalogItemId(rowId);
    },
  } satisfies ICheckboxCellOwnProps<CatalogItemNode>,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => {
    const { data: catalogItemNode } = params.node ?? {};
    if (catalogItemNode) {
      const rowId = getRowId(catalogItemNode);
      return catalogItemNode.catalogItem && appStore.ui.selectedCatalogItemIds.includes(rowId);
    }
    return false;
  },
  headerComponent: CheckboxHeaderCell,
  headerComponentParams: {
    getIndeterminate: (): boolean => {
      const selectedCatalogItemsAmount = appStore.ui.selectedCatalogItemIds.length;
      const itemsAmount = appStore.orgModel.catalogItems.totalItems;
      return !!selectedCatalogItemsAmount && itemsAmount > selectedCatalogItemsAmount;
    },
    getChecked: (): boolean => {
      const selectedCatalogItemsAmount = appStore.ui.selectedCatalogItemIds.length;
      const itemsAmount = appStore.orgModel.catalogItems.totalItems;
      return !!itemsAmount && itemsAmount === selectedCatalogItemsAmount;
    },
    onToggleSelection() {
      const catalogItemIds = appStore.orgModel.catalogItems.catalogItems.map(i => i.id);
      const refIds = appStore.orgModel.catalogItems.refIds;
      const allIds = appStore.ui.showPdmFlatten ? catalogItemIds : [...catalogItemIds, ...refIds];
      const selectedCatalogItemsAmount = appStore.ui.selectedCatalogItemIds.length;
      appStore.ui.setSelectedCatalogItemIds(selectedCatalogItemsAmount ? [] : allIds);
    },
  } satisfies ICheckboxHeaderCellOwnProps,
  sortIndex: 0,
  cellClass: "ag-actions-cell",
  headerClass: checkboxHeaderCellStyles.checkboxHeaderCellWrapper,
};

export const actionsColumnDef = {
  colId: CatalogItemTableColumn.Actions,
  headerName: "",
  resizable: false,
  lockPosition: "left" as const,
  pinned: "left" as const,
  sortIndex: 0,
  width: 50,
  cellClass: "ag-actions-cell",
  cellRenderer: PdmActionsCell,
};

export const thumbnailColumnDef = {
  colId: CatalogItemTableColumn.Thumbnail,
  headerName: "Thumbnail",
  width: 76,
  sortIndex: 5,
  cellRenderer: PdmThumbnailCell,
  cellClass: pdmThumbnailCellStyles.pdmThumbnailCell,
  resizable: false,
};

export const commentsColumnDef = {
  sortIndex: 3,
  colId: CatalogItemTableColumn.Comments,
  headerName: "Comments",
  width: 40,
  resizable: false,
  cellRenderer: CommentsCell,
};

export const versionColumnDef: ColDef = {
  sortIndex: 4,
  colId: CatalogItemTableColumn.Index,
  headerName: "Rev",
  cellClass: versionCellStyles.versionCell,
  width: 50,
  minWidth: 50,
  cellRenderer: VersionCell,
};

export const partNumberColumnDef = {
  sortIndex: 1,
  colId: CatalogItemTableColumn.PartNumber,
  headerName: "Part #",
  width: 100,
  editable: true,
  cellRenderer: TextCellRenderer,
  cellEditor: PartNumberCellEditor,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => params.data?.catalogItem.partNumber ?? "",
  valueSetter: (node: ValueSetterParams<CatalogItemNode>) => {
    node.data?.catalogItem.setPartNumber(node.newValue);
    return true;
  },
};

export const statusColumnDef = {
  sortIndex: 7,
  colId: CatalogItemTableColumn.Status,
  headerName: "Status",
  width: 123,
  minWidth: 123,
  cellRenderer: StatusCell,
};

export const parentRefsColumnDef = {
  sortIndex: 5,
  colId: CatalogItemTableColumn.ParentRefs,
  headerName: "Parent",
  width: 150,
  minWidth: 150,
  cellRenderer: ParentRefsCell,
};

export const childRefsColumnDef = {
  sortIndex: 6,
  colId: CatalogItemTableColumn.ChildRefs,
  headerName: "Child",
  width: 150,
  minWidth: 150,
  cellRenderer: ChildRefsCell,
};

export const partTypeColumnDef = {
  sortIndex: 6,
  colId: CatalogItemTableColumn.PartType,
  headerName: "Type",
  width: 150,
  minWidth: 150,
  cellRenderer: PartTypeCell,
};

export const descriptionColumnDef = {
  sortIndex: 8,
  colId: CatalogItemTableColumn.Description,
  headerName: "Description",
  width: 300,
  editable: true,
  cellRenderer: TextCellRenderer,
  cellEditor: TextCellEditor,
  cellEditorParams: { stopOnBlur: true },
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => params.data?.catalogItem.description ?? "",
  valueSetter: (node: ValueSetterParams<CatalogItemNode>) => {
    node.data?.catalogItem.setDescription(node.newValue);
    return true;
  },
};

export const materialColumnDef = {
  sortIndex: 9,
  colId: CatalogItemTableColumn.Material,
  headerName: "Primary material",
  width: 125,
  editable: true,
  cellClass: "ag-version-cell",
  cellRenderer: ValueCell,
  cellEditor: TextCellEditor,
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.Material),
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.Material),
};

export const weightColumnDef = {
  sortIndex: 10,
  colId: CatalogItemTableColumn.Weight,
  headerName: "Weight",
  width: 70,
  editable: true,
  cellClass: "ag-right-aligned-cell ag-version-cell",
  cellRenderer: ValueCell,
  cellEditor: PositiveDecimalCellEditor,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.Weight),
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.Weight),
};

export const weightUnitColumnDef = {
  sortIndex: 10,
  colId: CatalogItemTableColumn.WeightUnit,
  headerName: "",
  width: 50,
  cellRenderer: WeightUnitCell,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.WeightUnit),
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.WeightUnit),
};

export const supplierColumnDef = {
  sortIndex: 11,
  colId: CatalogItemTableColumn.Supplier,
  headerName: "Supplier",
  width: 125,
  editable: true,
  cellClass: "ag-version-cell",
  cellRenderer: ValueCell,
  cellEditor: TextCellEditor,
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.Supplier),
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.Supplier),
};

export const quantityColumnDef = {
  sortIndex: 12,
  colId: CatalogItemTableColumn.Quantity,
  headerName: "BOM Qty",
  width: 125,
  editable: true,
  cellClass: "ag-version-cell",
  cellRenderer: ValueCell,
  cellEditor: PositiveIntegerCellEditor,
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.Quantity),
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.Quantity),
};

export const costColumnDef = {
  sortIndex: 13,
  colId: CatalogItemTableColumn.Cost,
  headerName: "Cost",
  width: 70,
  editable: true,
  cellClass: "ag-right-aligned-cell ag-version-cell",
  cellRenderer: ValueCell,
  cellEditor: PositiveDecimalCellEditor,
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.Cost),
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.Cost),
};

export const costCurrencyColumnDef = {
  sortIndex: 13,
  colId: CatalogItemTableColumn.CostCurrency,
  headerName: "",
  width: 50,
  cellRenderer: CostCurrencyCell,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellValueGetter(params, CatalogItemTableColumn.CostCurrency),
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellValueSetter(params, CatalogItemTableColumn.CostCurrency),
};

export const leadTimeColumnDef = {
  sortIndex: 14,
  colId: CatalogItemTableColumn.LeadTime,
  headerName: "Lead time",
  cellClass: "ag-right-aligned-cell ag-version-cell",
  minWidth: 84,
  editable: true,
  cellRenderer: ValueCell,
  cellEditor: PositiveIntegerCellEditor,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) => versionCellLeadTimeValueGetter(params, CatalogItemTableColumn.LeadTime),
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) => versionCellLeadTimeValueSetter(params, CatalogItemTableColumn.LeadTime),
};

export const leadTimePeriodColumnDef = {
  sortIndex: 14,
  colId: CatalogItemTableColumn.LeadTimePeriod,
  headerName: "",
  minWidth: 80,
  cellRenderer: LeadTimePeriodCell,
  valueGetter: (params: ValueGetterParams<CatalogItemNode>) =>
    versionCellLeadTimeValueGetter(params, CatalogItemTableColumn.LeadTimePeriod),
  valueSetter: (params: ValueSetterParams<CatalogItemNode>) =>
    versionCellLeadTimeValueSetter(params, CatalogItemTableColumn.LeadTimePeriod),
};

export const sourceColumnDef = {
  sortIndex: 15,
  colId: CatalogItemTableColumn.Source,
  headerName: "Make/Buy",
  width: 125,
  cellRenderer: SourceCell,
};

export const autoGroupColumnDef: ColDef = {
  headerName: "Part name",
  width: 250,
  minWidth: 50,
  resizable: true,
  sortIndex: 2,
  cellClass: pdmNameCellStyles.pdmNameCellWrap,
  cellRenderer: PdmNameCell,
  getQuickFilterText: (params: GetQuickFilterTextParams<CatalogItemNode>) => params.data.catalogItem.name,
};

export const exportableColumnKeys = [
  CatalogItemTableColumn.AutoGroupColumn,
  CatalogItemTableColumn.PartNumber,
  CatalogItemTableColumn.Thumbnail,
  CatalogItemTableColumn.PartType,
  CatalogItemTableColumn.Status,
  CatalogItemTableColumn.Description,
  CatalogItemTableColumn.Material,
  CatalogItemTableColumn.Weight,
  CatalogItemTableColumn.Supplier,
  CatalogItemTableColumn.Index,
  CatalogItemTableColumn.Quantity,
  CatalogItemTableColumn.Cost,
  CatalogItemTableColumn.LeadTime,
  CatalogItemTableColumn.LeadTime,
];

export const colDefs: ColDef<CatalogItemNode>[] = [
  checkboxColumnDef,
  actionsColumnDef,
  partNumberColumnDef,
  commentsColumnDef,
  versionColumnDef,
  thumbnailColumnDef,
  partTypeColumnDef,
  parentRefsColumnDef,
  childRefsColumnDef,
  statusColumnDef,
  descriptionColumnDef,
  materialColumnDef,
  weightColumnDef,
  weightUnitColumnDef,
  supplierColumnDef,
  quantityColumnDef,
  costColumnDef,
  costCurrencyColumnDef,
  leadTimeColumnDef,
  leadTimePeriodColumnDef,
  sourceColumnDef,
];
