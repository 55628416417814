import { useCallback, useRef, useState } from "react";
import { InputGroup, Intent } from "@blueprintjs/core";
import { useScrollGridToLastRow } from "@hooks/useScrollGridToLastRow";
import { ColDef, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, IRowDragItem, IsFullWidthRowParams } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CreateNewInput from "@components/Shared/CreateNewInput/CreateNewInput";
import Table, { TTableProps } from "@components/Table/Table";
import { Tooltip } from "@components/Tooltip";

import "./FormattedTable.scss";

export enum FormattedTableRowType {
  CreateNewInput = "CreateNewInput",
}

interface IFormattedTableProps<T> extends TTableProps<T> {
  title: string;
  description: string;
  addNewLabel?: string;
  defaultDragText?: string;
  getValidationErrorMessage?(label: string): string;
  onAddNewRow(label: string): void;
}

interface IFormattedTableBaseRowData {
  id: string;
  label: string;
}

const PINNED_ROW_HEIGHT = 52;
const ROW_HEIGHT = 49;

const defaultColDef: ColDef = {
  editable: false,
  autoHeight: true,
  suppressMovable: false,
  singleClickEdit: true,
  lockPinned: true,
  headerComponentParams: {
    enableMenu: false,
  },
};

const gridOptions: GridOptions = {
  rowDragManaged: true,
  suppressMoveWhenRowDragging: true,
  defaultColDef,
  getRowId: (row: GetRowIdParams<IFormattedTableBaseRowData>) => row.data.id,
  isFullWidthRow: (params: IsFullWidthRowParams) => params.rowNode.data.type === FormattedTableRowType.CreateNewInput,
  getRowHeight: params => (params.node.rowPinned ? PINNED_ROW_HEIGHT : ROW_HEIGHT),
};
const createNewInputRowData = [{ type: FormattedTableRowType.CreateNewInput, id: "create-new-input" }];

const FormattedTable = <T extends IFormattedTableBaseRowData>(props: IFormattedTableProps<T>) => {
  const {
    title,
    description,
    addNewLabel = "Add new",
    className,
    rowData,
    defaultDragText = "Row",
    getValidationErrorMessage,
    onAddNewRow,
    ...restProps
  } = props;
  const [filterString, setFilterString] = useState("");
  const [showCreateNewInput, setShowCreateNewInput] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [gridApi, setGridApi] = useState<GridApi>();
  const scrollToBottom = useScrollGridToLastRow(gridApi);

  const regex = new RegExp(filterString, "i");
  const filteredRowData = rowData?.filter((data: T) => regex.test(data.label)) ?? [];

  const handleGridReady = useCallback((api: GridReadyEvent<T>) => {
    setGridApi(api.api);
  }, []);

  const handleAddNewRow = useCallback(
    (label: string) => {
      onAddNewRow(label);
      scrollToBottom();
      return true;
    },
    [onAddNewRow, scrollToBottom]
  );

  const handleValidation = useCallback(
    (label: string) => {
      const validationErrorMsg = getValidationErrorMessage?.(label);
      return { isValid: !validationErrorMsg, message: validationErrorMsg };
    },
    [getValidationErrorMessage]
  );

  const renderCreateNewInput = useCallback(
    () => (
      <CreateNewInput
        className="formatted-table--create-new-input"
        formGroupClassName="formatted-table--create-new-input-form-group"
        validateBeforeSubmit={handleValidation}
        placeholder={addNewLabel}
        tryCreate={handleAddNewRow}
        rightElement={
          <Tooltip content="Close">
            <Button onClick={() => setShowCreateNewInput(false)} icon="cross" e2eIdentifiers="close" minimal />
          </Tooltip>
        }
        autoFocus
      />
    ),
    [addNewLabel, handleAddNewRow, handleValidation]
  );

  const getRowDragText = useCallback(
    (params: IRowDragItem) => {
      const data = params.rowNode?.data as T;
      return data.label || defaultDragText;
    },
    [defaultDragText]
  );

  return (
    <div className="formatted-table" ref={wrapperRef}>
      <div className="formatted-table--inner-container">
        <div className="formatted-table--header">
          <div className="formatted-table--heading-row">
            <h1 className="formatted-table--heading">{title}</h1>
            <div className="formatted-table--heading-right-container">
              <InputGroup
                className="formatted-table--search"
                leftIcon="search"
                placeholder="Search for Definitions"
                value={filterString}
                onChange={event => setFilterString(event.target.value)}
              />
              <Button
                className="formatted-table--button"
                icon="plus"
                onClick={() => setShowCreateNewInput(true)}
                e2eIdentifiers="add-new-row"
                intent={Intent.PRIMARY}
                large
              >
                {addNewLabel}
              </Button>
            </div>
          </div>
          <div className="formatted-table--subheading">{description}</div>
        </div>
        <Table<T>
          rowData={filteredRowData}
          className={classNames("formatted-table--main", className)}
          pinnedTopRowData={showCreateNewInput ? createNewInputRowData : undefined}
          fullWidthCellRenderer={renderCreateNewInput}
          gridOptions={gridOptions}
          disableAutoHeight
          onGridReady={handleGridReady}
          rowDragText={getRowDragText}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default observer(FormattedTable);
